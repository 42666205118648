
<template>
  <div class="flex justify-center items-center ">
    <loading :active="isLoading" :is-full-page="true" :lock-scroll="true"></loading>
    <div class="w-full ">
      <!-- <div class=" w-10/12 md:10/12"> -->
      <div class="gradient"></div>
      <div class="w-full flex flex-col justify-center items-center mt-2 mb-2 ">
        <img src="@/assets/image/svg_logo.svg" alt="" class=" pb-3 md:pb-5 logo-lg hidden md:block" />
        <img src="@/assets/image/logo-sm.svg" alt="" class=" pb-3 md:pb-5 logo-lg md:hidden" />
        <!-- <img src="@/assets/image/logo_sm.png" alt="" class="pb-3 md:pb-5 md:hidden logo-sm " /> -->
        <p class="text-center text-grayColor text-xs md:text-lg ">
          打造最具真實的用餐評論，<br> 僅限透過Joymap訂位才擁有評論權限唷！
        </p>
      </div>

      <div class="flex flex-col m-auto commentMain">
        <div class="md:mt-10 flex items-center" v-if="!loading">
          <div class="logoImg mr-5" v-if="orderData.store.images.logos.length > 0">
            <img :src="orderData.store.images.logos[0]" class="w-full h-full object-cover" alt="storeLogo">
          </div>

          <div class="bg-grayColor-extralight flex justify-center items-center mr-5 " v-if="orderData.store.images.logos.length === 0">
            <p class="comment-noLogo flex justify-center items-center">店家尚未提供logo</p>
          </div>
          <div>
            <h1 class="" v-if="!loading">
              {{ orderData.store.name }}
            </h1>
          </div>
        </div>

        <div class="flex flex-col justify-center items-center rounded-md py-5 md:py-10 px-8 md:px-14 survey-box">
          <p class="mb-4 text-xs md:text-lg font-medium">餐廳整體評價</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 0 ">滿意嗎?</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 1 ">糟透了</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 2 ">微差</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 3 ">一般</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 4 ">蠻好</p>
          <p class="text-main md:text-3xl font-medium mb-5" v-if="userComment.score === 5 ">非常棒</p>

          <div class="flex">
            <span class="star-item mr-2 cursor-pointer" :class="item <= userComment.score?'on':'offGray'" v-for="item in count" :key="item" @click="clickMainStar(item)"></span>
          </div>

          <div class="w-full border-t-2 border-gray-200 my-5 text-grayColor-darkest text-xs md:text-lg" v-if="userComment.score > 0 ">
            <p class="md:mb-4 text-8 md:text-lg font-medium text-center p-5 text-grayColor ">其他評價</p>

            <div class="md:px-10" v-for="(item,index) in orderData.comment_score_settings" :key="index">
              <div class="flex justify-center items-center pb-2 md:pb-5">
                <span class="w-4/12 md:w-1/2 text-center ">{{item.name}}</span>
                <div class="flex md:w-1/2">
                  <span class="star-item mr-2 cursor-pointer" :class="item <= userComment.sub_score[index+1]?'on':'offGray'" v-for="item in count" :key="item" @click="toggleItem(item,index)">
                  </span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Form v-slot="{ errors }" :validation-schema="schema" @submit="onSubmit">
          <div class=" my-8 md:my-10">
            <div class="md:flex md:mb-10 mb-5">
              <div class="w-full md:w-1/2 mr-2">
                <label class="text-grayColor-darkest font-semibold text-xs md:text-lg">暱稱</label>
                <div class=" relative">
                  <Field name="name" type="text" v-model="userComment.name" class="w-full py-1 md:py-2 px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="請輸入暱稱" :class="{ 'border-status-red': errors.name }" onkeydown="if(event.keyCode==13){return false;}" />
                  <img src="@/assets/image/svg_commentcheck.svg" alt="addPicture" class="absolute top-4 right-2 " v-if="!isNameEmpty" />
                  <img src="@/assets/image/svg_commentchecked.svg" alt="addPicture" class="absolute top-4 right-2 " v-if="isNameEmpty" />
                  <div class="text-xs md:text-base text-red-500">{{errors.name}}</div>
                </div>
              </div>
              <div class="mt-5 md:mt-0 w-full md:w-1/2 mr-2">
                <label class="text-grayColor-darkest font-semibold text-xs md:text-lg">標題</label>
                <div class="relative">
                  <Field name="title" type="text" v-model="userComment.title" class="w-full py-1 md:py-2 px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="請輸入標題" :class="{ 'border-status-red': errors.title }" onkeydown="if(event.keyCode==13){return false;}" />
                  <img src="@/assets/image/svg_commentcheck.svg" alt="addPicture" class=" absolute top-4 right-2 " v-if="!isTitleEmpty" />
                  <img src="@/assets/image/svg_commentchecked.svg" alt="addPicture" class="absolute top-4 right-2 " v-if="isTitleEmpty" />
                  <div class="text-xs md:text-base text-red-500">{{errors.title}}</div>
                </div>
              </div>
            </div>

            <div class="flex items-center mb-5  md:mb-10">
              <div class="flex flex-wrap items-center with-four">
                <div class="uploadImage" v-for="(image, index) in previewImage" :key="image">
                  <img class="rounded-md image h-full w-full" :src="image">
                  <img src="@/assets/image/svg_remove_photo.svg" alt="addPicture" class="deleteBtn hidden md:block" @click="deletePhoto(index)" />
                  <img src="@/assets/image/svg_remove_photo.svg" alt="addPicture" class="deleteBtn w-3 block md:hidden" @click="deletePhoto(index)" />
                </div>
                <button class="uploadImageBtn md:mb-5 mb-2 border border-main rounded-lg flex flex-col justify-center items-center cursor-pointer focus:outline-none " v-if="userComment.image.length < 8">
                  <img src="@/assets/image/svg_camera.svg" alt="addPicture" class="mb-3 md:block hidden" />
                  <img src="@/assets/image/camer_sm.svg" alt="addPicture" class="bolck md:hidden w-5" />
                  <div class="smallsize-font small-font md:ml-0 text-main md:text-sm md:pl-0 w-28 ">
                    <p>新增用餐照片</p>
                    <p>最多上傳八張</p>
                  </div>
                  <input type="file" name="image" id="uppic" multiple accept="image/jpeg,image/jpg,image/png" @change="changeImage($event)" ref="files" class="uploadImageBtn cursor-pointer uploadImageInput">
                </button>
              </div>
            </div>

            <div class="md:mt-10">
              <p class="text-grayColor-darkest font-semibold text-xs md:text-lg">評論內容</p>
              <Field name="comment" as="textarea" cols="30" rows="10" v-model="userComment.body" class="w-full  py-2 px-3 rounded-md mt-1 focus:outline-none focus:ring-2 focus:ring-main focus:border-transparent border" placeholder="用餐心得..." :class="{ 'border-status-red': errors.comment }" onkeydown="if(event.keyCode==13){return true;}" />
              <div class="text-red-500 md:text-sm text-xs pt-2">{{errors.comment}}</div>
              <p class="text-grayColor md:text-sm text-xs pt-2" v-if="!errors.comment">
                評論不得少於50個字
              </p>
            </div>
          </div>
          <div class="checkContainer flex">
            <span class="text-main text-sm font-bold">*</span>
            <label for="acceptTerms">
              <input name="acceptTerms" type="checkbox" id="acceptTerms" :value="true" v-model="checked" class="checkbox" />
              <!-- <input name="acceptTerms" v-model="checked" type="checkbox" id="acceptTerms" :value="true" :unchecked-value="false" class="checkbox" /> -->
              <span class="checkmark top-1 rounded-sm cursor-pointer"></span>
              <p class="text-xs   md:text-sm text-grayColor-darkest pl-7 cursor-pointer">
                我確認這篇評論是我對此餐廳的真實評論，沒有個人或者商業關係，也沒有接受任何獎金或費用。<br> 提交評論即表示您同意 Joymap 的<a href="/terms-of-service" class="text-main underline">服務條款</a> 與 <a href="/privacy-policy" class="text-main underline">隱私權政策</a> 隱私權政策。
                <!-- <a href="#" class="text-main underline">JoyMap</a> -->
              </p>
            </label>
          </div>
          <!-- <div class="text-red-500 text-sm pt-5">{{errors.acceptTerms}}</div> -->
          <div class="text-red-500 text-sm" v-if="!checked && checked !== '' ">請同意並閱讀隱私條款</div>
          <div class="flex justify-center my-8 md:my-16">
            <a class="flex justify-center items-center btn btn-gray mr-5 text-center cursor-pointer " @click="cancle">取消評論</a>
            <button class="btn btn-main " type="submit" :class="{disabled : userComment.body.length < 50 || !checked || userComment.title.length === 0 || userComment.name.length === 0 || userComment.title.length === 0 || userComment.score === 0} ">送出評論</button>
          </div>
        </Form>
      </div>

    </div>
  </div>
  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Field, Form } from "vee-validate";
import { useForm } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/dist/sweetalert2.min.css";

export default {
  name: "Comment",
  props: {
    count: {
      type: Number,
      default: 5,
    },
    score: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Footer,
    Loading,
    Field,
    Form,
  },
  setup() {
    const { isSubmitting } = useForm();
    const schema = Yup.object().shape({
      title: Yup.string()
        .max(24, "請輸入24字以內的標題")
        .trim()
        .required("請輸入標題"),
      name: Yup.string().trim().required("請填入姓名"),
      comment: Yup.string()
        .trim()
        .min(50, "評論不得少於50字")
        .required("評論不得少於50字"),
      // acceptTerms: Yup.bool().required("請同意並閱讀隱私條款"),
    });
    return {
      schema,
      isSubmitting,
    };
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      checked: "",
      id: "", //storeID
      orderData: {},
      userComment: {
        id: "", //orderId
        title: "",
        body: "",
        name: "",
        phone: "",
        score: this.score,
        sub_score: [],
        image: [],
      },
      previewImage: [],
    };
  },
  methods: {
    getReservation() {
      const api = `${process.env.VUE_APP_API}/order/${this.userComment.id}`;
      this.loading = true;
      this.$http
        .get(api)
        .then((response) => {
          this.orderData = response.data.return;
          this.userComment.name = this.orderData.order.name;
          this.userComment.phone = this.orderData.order.phone;
          this.id = this.orderData.store.id;
          localStorage.setItem("storeID", this.id);
          localStorage.setItem("phone", this.userComment.phone);
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            this.loading = false;
          }
        });
    },
    changeImage(e) {
      let input = e.target;
      let count = input.files.length;
      let index = 0;
      const totalImage = input.files.length + this.previewImage.length;
      if (input.files.length > 8 || totalImage > 8) {
        alert("最多只能上傳8張照片");
        return;
      } else {
        if (input.files) {
          while (count--) {
            let reader = new FileReader();
            reader.onload = (e) => {
              this.previewImage.push(e.target.result);
            };
            this.userComment.image.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
            index++;
          }
        }
      }
    },
    toggleItem(item, index) {
      this.userComment.sub_score[index + 1] = item;
    },
    onSubmit() {
      const api = `${process.env.VUE_APP_API}/order/comment`;
      const formData = new FormData();
      formData.append("id", this.userComment.id);
      formData.append("name", this.userComment.name);
      formData.append("title", this.userComment.title);
      formData.append("body", this.userComment.body);
      formData.append("phone", this.userComment.phone);
      formData.append("score", this.userComment.score);
      formData.append("sub_score[1]", this.userComment.sub_score[1]);
      formData.append("sub_score[2]", this.userComment.sub_score[2]);
      formData.append("sub_score[3]", this.userComment.sub_score[3]);
      formData.append("sub_score[4]", this.userComment.sub_score[4]);
      this.userComment.image.forEach((image, index) => {
        formData.append(`image[${index}]`, image);
      });

      this.isLoading = true;
      this.$http
        .post(api, formData, {
          headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8",
          },
        })
        .then((response) => {
          this.isLoading = false;
          this.$router.push({
            name: "CommentSuccess",
            params: {
              orderId: this.$route.params.orderId,
              storeId: this.id,
              phone: this.orderData.order.phone,
            },
          });
        })
        .catch((error) => {
          if (error.response.data) {
            this.isLoading = false;
            Swal.fire({
              icon: "error",
              title: "此訂單已評論過",
              //  title: error.response.data.msg,
              confirmButtonText: "知道了",
            }).then((result) => {
              if (result.isConfirmed) {
                this.$router.push({
                  path: `/commentsuccess`,
                });
              }
            });
          }
        });
    },
    deletePhoto(index) {
      this.userComment.image.splice(index, 1);
      this.previewImage.splice(index, 1);
    },
    cancle() {
      this.$router.push(`/store/${this.id}`);
    },
    clickMainStar(item) {
      if (this.userComment.score === 0) {
        this.userComment.sub_score[1] = item;
        this.userComment.sub_score[2] = item;
        this.userComment.sub_score[3] = item;
        this.userComment.sub_score[4] = item;
      }
      this.userComment.score = item;
    },
  },
  computed: {
    isNameEmpty() {
      if (this.userComment.name.trim() === "") {
        return false;
      } else {
        return true;
      }
    },
    isTitleEmpty() {
      if (this.userComment.title.trim() === "") {
        return false;
      } else {
        return true;
      }
    },
  },

  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.userComment.id = this.$route.params.orderId;
    this.getReservation();
  },
};
</script>


<style scoped>
h1 {
  font-size: 20px;
  font-weight: 700;
}

@media (min-width: 768px) {
  h1 {
    font-size: 34px;
  }
}
.uploadImage {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 68px;
  width: 68px;
  margin: 0 7px 8px 0;
}
.with-four :nth-child(4),
.with-four :nth-child(8) {
  margin-right: 0px;
}

@media (min-width: 768px) {
  .uploadImage {
    height: 144px;
    width: 144px;
    margin: 0 16px 20px 0;
  }
}

@media (min-width: 1024px) {
  .uploadImage {
    height: 160px;
    width: 160px;
    margin: 0 22px 20px 0;
  }
}
</style>

